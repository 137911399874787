.footer {
    // position: fixed;
    bottom:0;
    width: 100%;
    height: 100px;
    // margin-top: 20px;
    padding-top:20px;
    padding-bottom:20px;
    //background-color: $background-color;
    background-color: #f6f6f9;

    //color: $text-dark-color;
    color: #3f4c7c;
}
