header {
  // background: $high-light-color;
  // background: rgba(255, 255, 255, 0.95);
  text-align: center;
  // position: fixed;
  z-index: 999;
  width:100%;
  // background: #f6f6f9;
  background: $accent-color;
  // padding-left: 30px;
  // padding-right: 30px;
  // max-width: 1200px;
  // margin-left: auto !important;
  // margin-right: auto !important;
  // Glass
  // background: rgba(255, 255, 255, 0.41);
  //background: rgba(0, 0, 153, 0.81);
  //background: rgba(0, 0, 153, 1);
  // background: #FFF;
  // border-radius: 16px;
  // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  //backdrop-filter: blur(11.5px);
  //-webkit-backdrop-filter: blur(11.5px);
  padding-top: 21px;
  padding-bottom: 21px;
  display: flex;
  align-items: center;
  height:91px
}

.headerDiv{
  display: flex;
  align-items: center;
  width:100%;
  max-width: 1200px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 30px;
  padding-right: 30px;
  height:100px
}

.logo{
  padding: 10px 0 10px 0;
}

/* changed this from the tutorial video to
   allow it to gain focus, making it tabbable */
.nav-toggle {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.nav-toggle:focus ~ .nav-toggle-label {
  outline: 3px solid rgba(lightblue, .75);
}

.nav-toggle-label {
  position:absolute;
  top: 50px;
  // left: 0;
  right: 20px;
  // margin-left: 1em;
  // margin-right: 1em;
  // height: 100%;
  // display: flex;
  // align-items: center;
}

.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
  display: block;
  // background: $text-dark-color;
  background: $text-light-color;
  height: 2px;
  width: 2em;
  border-radius: 2px;
  position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
  content: '';
  position: absolute;
}

.nav-toggle-label span::before {
  bottom: 7px;
}

.nav-toggle-label span::after {
  top: 7px;
}

nav {
  position: absolute;
  // text-align: left;
  top: 91px;
  left: 0;
  float: right;
  // background: #f6f6f9;
  background: $accent-color;
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
  // display:flex;
  align-items: center;
  z-index: 999;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  /* Added for mobile */
  flex-direction: column;
}

nav li {
  margin-bottom: 1em;
  margin-left: 1em;
  // vertical-align: middle;
  // text-align: center;
  padding:10px 0 10px 0;
}

nav a {
  // color: $text-dark-color;
  color: $text-light-color;
  text-decoration: none;
  // font-size: 1.2rem;
  font-size: 16px;
  font-weight: 500;
  // text-transform: uppercase;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  text-align: center;
  vertical-align: middle;
}

nav a:hover {
  // color: $text-dark-color;
  // color: $text-light-color;
  color: $accent-color;
  transition: all 0.2s ease;
}

.nav-toggle:checked ~ nav {
  transform: scale(1,1);
}

.nav-toggle:checked ~ nav a {
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

@media screen and (min-width: 800px) {
  .nav-toggle-label {
    display: none;
  }

  // header {
    // display: grid;
    // grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;
  // }
  
  .logo {
    grid-column: 2 / 3;
  }
  
  nav {
    // all: unset; /* this causes issues with Edge, since it's unsupported */
    
    /* the following lines are not from my video, but add Edge support */
    // position: relative;
    text-align: left;
    transition: none;
    transform: scale(1,1);
    background: none;
    top: initial;
    left: initial;
    /* end Edge support stuff */
    
    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* Removed when not mobile */
    position: static;
    display:flex;
    align-items: center;
  }
  
  nav ul {
    display: flex;
    flex-direction: row;
  }
  
  nav li {
    margin-left: 1em;
    margin-bottom: 0;
  }
  
  nav a {
    opacity: 1;
    position: relative;
  }
  
  // nav a::before {
    // content: '';
    // display: block;
    // height: 5px;
    // background: black;
    // position: absolute;
    // top: -.75em;
    // left: 0;
    // right: 0;
    // transform: scale(0, 1);
    // transition: transform ease-in-out 250ms;
  // }
  
  // nav a:hover::before {
    // transform: scale(1,1);
  // }
}
